<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
            Condicion Pago
        </v-card-subtitle>
        <v-form ref="form" lazy-validation>
            <v-card-text class="d-flex">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6" v-if="idProveedor == -1">
                                <ProveedorSelect  :outlined="true"  :rules="reglas.idProveedor" v-model="datos.idProveedor"
                                    :clearable="true" :dense="true" />
                            </v-col>
                            <v-col cols="12" md="6" v-if="idMoneda == -1">
                                <MonedaSelect :outlined="true"   :rules="reglas.idMoneda" v-model="datos.idMoneda" :clearable="true"
                                    :dense="true" />
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-text-field :rules="reglas.descripcion" v-model="datos.descripcion"
                                    label="Descripcion *" required outlined dense hide-details="auto"
                                    class="mb-6 mt-5"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field :rules="reglas.cantidadPlazo" v-model="datos.cantidadPlazo"
                                    label="Cantidad Plazo *" required outlined dense hide-details="auto"
                                    class="mb-6 mt-5"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch v-model="datos.indActivo" :label="`Ind Activo`"></v-switch>
                            </v-col>
                        </v-row>
                        {{ datos }}
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn color="secondary" class="me-3  " @click="limpiar()" small> Limpiar
                        </v-btn>
                        <v-btn color="primary" class="me-3  " small @click="Guardar()"> Guardar </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import CondicionPagoServices from '@/api/servicios/CondicionPagoServices'

import ProveedorSelect from '@/views/sistema/producto/catalogo/proveedor/componentes/Select.vue'
import MonedaSelect from '@/views/sistema/configuracion/moneda/componentes/Select.vue'
export default {
    components: {
        ProveedorSelect,
        MonedaSelect
    },
    props: {
        idProveedor: {
            type: Number,
            default: -1
        },
        idMoneda: {
            type: Number,
            default: -1
        }
    },
    setup(props, context) {

        const guardando = ref(false)
        const form = ref(null)

        const datoInit = {
            "id": -1,
            idProveedor: null,
            idMoneda: null,
            "cantidadPlazo": "",
            "descripcion": "",
            "indActivo": true
        }
        const datos = ref({ ...datoInit })
        watch(props, () => {
            if (props.idProveedor != -1) {
                datos.value.idProveedor = props.idProveedor
            }
            if (props.IdMoneda != -1 ) {
                datos.value.idMoneda = props.idMoneda
            }
        })
        const reglas = {
            descripcion: [

                v => !!v || "descripcion es requerido"
            ],
            idProveedor: [

                v => !!v || "idProveedor es requerido"
            ],
            idMoneda: [

                v => !!v || "idMoneda es requerido"
            ],
            cantidadPlazo: [

                v => !!v || "cantidadPlazo es requerido"
            ]
        }



        const limpiar = () => {
            datos.value = { ...datoInit };
            if (props.idProveedor != -1) {
                datos.value.idProveedor = props.idProveedor
            }
            if (props.IdMoneda != -1 ) {
                datos.value.idMoneda = props.idMoneda
            }
            form.value.resetValidation();
        }

        const cargandos = ref(false)


        const CargarEditar = (item) => {
            datos.value = item
            goTo(0)
        }
        const validarForm = () => {
            let val = form.value?.validate()

            return val
        }
        const ActualizarLista = item => {
            console.log(item)
            context.emit('GetActualizarLista', { ...item })

        }
        const Guardar = () => {
            guardando.value = true
            if (validarForm()) {
                try {

                    CondicionPagoServices.Actualizar(
                        store.state.user.idUsuario,
                        datos.value
                    ).then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            ActualizarLista(response.data.datos.datos)
                            limpiar()
                        } else {

                            store.commit('setAlert', {
                                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                                type: 'warning',
                            })
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            store.commit('setAlert', {
                                message: error,
                                type: 'error',
                            })
                        })
                        .finally(() => {
                            guardando.value = false
                        })
                } catch (error) {
                    //alert(error)
                    store.commit('setAlert', {
                        message: error,
                        type: 'error',
                    })
                }
            } else {
                store.commit('setAlert', {
                    message: 'Verifique que todos los datos estén completos',
                    type: 'warning',
                })
            }

        }


        return {
            reglas,
            datos,
            CargarEditar,
            cargandos,
            limpiar,
            Guardar,
            form,
            ActualizarLista
        }
    }
}

</script>