<template>
    <div>

        <v-autocomplete :prepend-icon="icon ? icons.mdiAlphaCCircle : null" :clearable="clearable" hide-details="auto"
            :disabled="disabled" v-model="select" :items="lista" label="Condicion Pago" item-value="id"
            item-text="nombre" :dense="outlined" :outlined="outlined" :loading="cargando" :rules="rules" 
            :append-outer-icon="icons.mdiPlusCircleOutline" 
             @click:append-outer="abrirModal()">
            <template slot="selection" slot-scope="data">
                {{ data.item.descripcion }}
            </template>
            <template slot="item" slot-scope="data">
                {{ data.item.descripcion }}
            </template>
        </v-autocomplete>

        <v-dialog v-model="dialog" width="750">
            <Actualizar @GetActualizarLista="GetActualizarLista" :idMoneda="idMoneda" :idProveedor="idProveedor"></Actualizar>
        </v-dialog>
        {{ idMoneda }}{{ idProveedor }}
    </div>

</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'

import CondicionPagoServices from '@/api/servicios/CondicionPagoServices.js'
import Actualizar from './Actualizar.vue'
export default {
    components:{
        Actualizar
    },
    props: {
        value: Number,
        jsonRespueta: Boolean,
        disabled: Boolean,
        dense: Boolean,
        outlined: Boolean,
        icon: Boolean,
        todos: Boolean,
        default: Number,
        clearable: Boolean,
        idProveedor: Number,
        idMoneda: Number,
        rules: Array
    },
    setup(props, contex) {
        const lista = ref([])
        const select = ref(null)
        const cargando = ref(false)
        const idProveedor2 = ref(null)
        const idMoneda2 = ref(null)
        const dialog = ref(false)
        watch(props, () => {
            select.value = props.value

            if (props.idProveedor != idProveedor2.value || props.idMoneda != idMoneda2.value) {
                idProveedor2.value = props.idProveedor
                idMoneda2.value = props.idMoneda
                cargarDatos()
            }
        })

        onBeforeMount(() => {
            cargarDatos()
            if (props.default != undefined) {
                select.value = props.default
            }
        })
        const abrirModal = () => {
            dialog.value = true
        }
        const GetActualizarLista = (item) => {
 
            lista.value.push(item)
            select.value = item.id
            dialog.value = false
        }
        const selectId = (id) => {

            select.value = id
        }
        const cargarDatos = () => {
            lista.value = []
            cargando.value = true
            try {

                CondicionPagoServices.ConsultarSelect({
                    IdProveedor: props.idProveedor == undefined ? '0' : props.idProveedor,
                    IdMoneda: props.idMoneda == undefined ? '-1' : props.idMoneda
                })
                    .then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            lista.value = response.data.datos
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargando.value = false
                    })
            } catch (error) {

                cargando.value = false
            }
        }
        watch(select, () => {
            if (props.jsonRespueta == true) {
                const valor = lista.value.find(item => (item.id == select.value))
                contex.emit('input',
                    valor == undefined ? null : valor
                )
            } else {
                contex.emit('input',
                    select.value
                )
            }



        })

        return {
            icons: {
                mdiAlphaCCircle,
                mdiPlusCircleOutline
            },
            lista,
            select,
            cargando,
            selectId,
            dialog,
            GetActualizarLista,
            abrirModal
        }
    },
}
</script>