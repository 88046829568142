
<template>
    <div>
        <v-card>
            <v-card-subtitle>Lista Compra

                <v-btn icon @click="cargar()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
                    <v-icon>{{ icons.mdiCached }}</v-icon>
                </v-btn>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="12" offset-md="8" md="4">
                        <v-text-field v-model="search" :append-icon="icons.mdiMagnify" label="Buscar" single-line
                            hide-details dense outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <!-- Table -->
            <v-data-table :headers="headers" :items="Lista" :search="search" :items-per-page="5" :loading="cargandoDatos"
                class="table-kitchen-sink"> 
                <template #[`item.accion`]="{ item }">
                    <BtnPdf :id="item.id" :tipoBtn="2"></BtnPdf>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <a class="pr-1" v-bind="attrs" v-on="on" @click="GetVer(item)">
                                <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                            </a>
                        </template>
                        <span>Ver</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <a class="pr-1" v-bind="attrs" v-on="on" @click="GetFinalizar(item)">
                                <v-icon :color="item.idEstatus == 3 || item.idEstatus == 5 ? 'success' : 'grey3'">{{
                                    icons.mdiCheck }}</v-icon>
                            </a>
                        </template>
                        <span>Finalizar</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                                <v-icon :color="item.idEstatus == 3  ? 'info' : 'grey3'">{{
                                    icons.mdiPencil }}</v-icon>
                            </a>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <a class="pr-2 " v-bind="attrs" v-on="on" @click="GetAnular(item)">
                                <v-icon :color="item.idEstatus != 14 ? 'error' : 'grey3'">{{ icons.mdiCancel }}</v-icon>
                            </a>
                        </template>
                        <span>Anular</span>
                    </v-tooltip>

                </template>
            </v-data-table>
            <ModalViewCompra @GetAnular="GetAnular" @GetEditar="GetEditar" @GetPdf="GetPdf" @GetFinalizar="GetFinalizar"
                ref="ModalViewCompraRef"></ModalViewCompra>
        </v-card>
    </div>
</template>

<script>
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import {
    mdiMagnify,
    mdiDeleteOutline,
    mdiStoreEdit,
    mdiPencilOutline,
    mdiAccountCancel,
    mdiAccountEdit,
    mdiAccountCheck,
    mdiCheck,
    mdiCancel,
    mdiStoreEditOutline,
    mdiPencil,
    mdiCached,
    mdiPhoneOutline,
    mdiEmail,
    mdiWeb,
    mdiEye
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import CompraServices from '@/api/servicios/CompraServices'
import ModalViewCompra from './ModalViewCompra.vue'
import Vue from 'vue'
import BtnPdf from './BtnPdf.vue'
export default {
    watch: {
        '$store.state.sucursalSelect.id': function () {
            this.cargar()
        }
    },
    components: {
        ModalViewCompra,
        BtnPdf
        
    },
    setup(props, context) {
        const search = ref('')
        const Lista = ref([])
        const ListaLoad = ref(false)
        const cargandoDatos = ref(false)
        const ModalViewCompraRef = ref(null)

        const GetEditar = item => {
            if (item.idEstatus == 3) {
            
                console.log(item)
                context.emit('GetEditar', { ...item })
            }


        }
        const GetVer = item => {
            ModalViewCompraRef.value.abrir(item.id)
        }
        const anulando = ref(false)

        const GetAnular = (item) => {
            if (item.idEstatus != 14) {
                anulando.value = true
                Vue.swal({
                    icon: "warning",
                    html:
                        '<h3>¿Seguro que quiere anular la compra? </h3>' +
                        `<p>${item.numeroDocumento}</p>`,
                    showCancelButton: true,
                    confirmButtonText: 'Si, Seguro',
                    showLoaderOnConfirm: true,
                    cancelButtonText: 'No',
                    preConfirm: () => {
                        return CompraServices.Anular(store.state.user.idUsuario, { id: item.id })
                            .then(response => {
                                if (response.data.estatus == true) {
                                    Vue.swal({
                                        icon: 'success',
                                        title: `Exito`,
                                        text: response.data.mensaje,
                                    })
                                    cargar()
                                } else {
                                    Vue.swal({
                                        icon: 'error',
                                        title: `Oops...`,
                                        text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                                    })
                                }
                                return true
                            })
                            .catch(error => {
                                Vue.swal({
                                    icon: 'error',
                                    title: `Oops...`,
                                    text: `hubo un error: ${error}`,
                                })
                                return false
                            })
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then(result => {
                    anulando.value = false
                })
            }
        }
        const finalizando = ref(false)
        const GetFinalizar = (item) => {
            if (item.idEstatus == 3 || item.idEstatus == 5) {
                finalizando.value = true
                Vue.swal({
                    icon: "warning",
                    html:
                        '<h3>¿Seguro que quiere finalizar la compra? </h3>' +
                        `<p>${item.numeroDocumento}</p>`,
                    showCancelButton: true,
                    confirmButtonText: 'Si, Seguro',
                    showLoaderOnConfirm: true,
                    cancelButtonText: 'No',
                    preConfirm: () => {
                        return CompraServices.Finalizar(store.state.user.idUsuario, { id: item.id })
                            .then(response => {
                                if (response.data.estatus == true) {
                                    Vue.swal({
                                        icon: 'success',
                                        title: `Exito`,
                                        text: response.data.mensaje,
                                    })
                                    cargar()
                                } else {
                                    Vue.swal({
                                        icon: 'error',
                                        title: `Oops...`,
                                        text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                                    })
                                }
                                return true
                            })
                            .catch(error => {
                                Vue.swal({
                                    icon: 'error',
                                    title: `Oops...`,
                                    text: `hubo un error: ${error}`,
                                })
                                return false
                            })
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then(result => {
                    finalizando.value = false
                })
            }
        }
        onBeforeMount(() => {
            cargar()
        })



        const cargar = () => {
            try {

                CompraServices.Consultar(
                    { idSucursal: store.state.sucursalSelect.id }
                )
                    .then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            Lista.value = response.data.datos
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargandoDatos.value = false
                    })
            } catch (error) {
                //alert(error)
                cargandoDatos.value = false
            }

        }
        const CambiarIndActivo = (item) => {
            console.log("aqui")
            console.log(item)
            cargandoDatos.value = true
            try {
                CompraServices.Actualizar(
                    store.state.user.idUsuario,
                    item.id,
                    item.descripcion,
                    !item.indActivo,
                    item.idCategoria
                ).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'success',
                        })
                        cargar()
                    } else {
                        store.commit('setAlert', {
                            message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                            type: 'warning',
                        })
                    }
                })
                    .catch(error => {
                        console.log(error)
                        store.commit('setAlert', {
                            message: error,
                            type: 'error',
                        })
                        cargandoDatos.value = false
                    })

            } catch (error) {
                store.commit('setAlert', {
                    message: error,
                    type: 'error',
                })
                cargandoDatos.value = false
            }


        }


        return {
            icons: {
                mdiMagnify,
                mdiDeleteOutline,
                mdiPencilOutline,
                mdiAccountCancel,
                mdiAccountEdit,
                mdiAccountCheck,
                mdiCheck,
                mdiCancel,
                mdiStoreEdit,
                mdiStoreEditOutline,
                mdiPencil,
                mdiCached,
                mdiPhoneOutline,
                mdiEmail,
                mdiWeb,
                mdiEye
            },
            search,
            headers: [
                { text: 'Documento', value: 'numeroDocumento' },
                { text: 'Area', value: 'area.nombre' },
                { text: 'estatus', value: 'estatus.descripcion' },
                { text: 'Fecha Creacion', value: 'fechaCreacion' },
                { text: 'Numero Factura', value: 'numeroFactura' },
                { text: 'Total', value: 'total' },
                { text: 'Proveedor', value: 'proveedor.razonSocial' },
                { text: 'Accion', value: 'accion', sortable: false },
            ],
            Lista,
            ListaLoad,
            GetEditar,
            cargar,
            config,
            cargandoDatos,
            CambiarIndActivo,
            GetVer,
            GetFinalizar,
            GetAnular,
            ModalViewCompraRef,
            anulando
        }
    },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
    .v-data-table-header {
        white-space: nowrap;
    }
}
</style>
