<template>
    <div>
        <v-card class="pb-2">

            <v-card-text>
                <v-card-subtitle>Datos Pricipal</v-card-subtitle>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12" lg="4" md="4" sm="6">

                            <AreaSelect :rules="reglas.idArea" :dense="true" :outlined="true" v-model="cabecera.idArea">
                            </AreaSelect>
                        </v-col>
                        <v-col cols="12" lg="8" md="4" sm="6"> 
                            <ProveedorSelect :rules="reglas.idProveedor" :clearable="true" v-model="cabecera.idProveedor"
                                :dense="true" :outlined="true">
                            </ProveedorSelect> 
                        </v-col> 
                    </v-row>
                    <v-row>
                        <v-col cols="12" lg="3" md="4" sm="6">
                            <MonedaSelectObjeto
                                :disabled="(cabecera.idProveedor == undefined || cabecera.idProveedor == null)"
                                :label="`Moneda Negociacion`" :dense="true" :outlined="true" v-model="cabecera.Moneda">
                            </MonedaSelectObjeto>

                        </v-col>
                        <v-col cols="12" lg="3" md="4" sm="6">
                            <CondicionPagoSelect :clearable="true" v-model="cabecera.idCondicionPago"
                                :disabled="(cabecera.idProveedor == undefined || cabecera.idProveedor == null)"
                                :idProveedor="cabecera.idProveedor" :idMoneda="cabecera.Moneda ? cabecera.Moneda.id : null"
                                :dense="true" :outlined="true">
                            </CondicionPagoSelect>
                        </v-col>



                        <v-col cols="12" lg="3" md="4" sm="6">

                            <TipoCambioSelect
                                :disabled="(cabecera.idProveedor == undefined || cabecera.idProveedor == null)"
                                :dense="true" :outlined="true" v-model="cabecera.idTipoCambio"></TipoCambioSelect>

                        </v-col>
                        <v-col cols="12" lg="3" md="4" sm="6">
                            <v-text-field v-model="cabecera.tasaCambio" :rules="reglas.tasaCambio"
                                :disabled="(cabecera.idProveedor == undefined || cabecera.idProveedor == null)"
                                hide-details="auto" outlined dense :label="`Tasa a ${monedaPais.simbolo} `"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field :rules="reglas.numeroFactura" hide-details="auto" v-model="cabecera.numeroFactura"
                                outlined dense label="Numero Factura"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <v-text-field :rules="reglas.numeroControl" hide-details="auto" outlined dense
                                label="Numero Control" v-model="cabecera.numeroControl"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="6">
                            <DatePicker :rules="reglas.FechaFactura" key="" :dense="true" :label="'Fecha Factura'"
                                :outlined="true" v-model="cabecera.FechaFactura"></DatePicker>
                        </v-col>
                    </v-row>
                </v-form>

            </v-card-text>
        </v-card>
        <v-card class="mt-2">
            <v-card-text>
                <v-card-subtitle>Detalles </v-card-subtitle>

                <v-form ref="formDetalle" lazy-validation>
                    <v-row>
                        <v-col cols="12" lg="4" md="3" sm="6">
                            <SelectProductoSucursal :rules="reglasDetalles.producto" :icon="false"
                                :idPadre="store.state.sucursalSelect.id" v-model="productoDetalle">
                            </SelectProductoSucursal>
                           
                        </v-col>
                        <v-col cols="12" lg="4" md="3" sm="6">
                            <TasaImpuestoSelect :rules="reglasDetalles.tasaImpuesto" :dense="true" :outlined="true"
                                v-model="tasaImpuesto" :padre="1">
                            </TasaImpuestoSelect>

                        </v-col>
                        <v-col cols="12" lg="4" md="3" sm="6">
                            <v-text-field :rules="reglasDetalles.cantidad" hide-details="auto" outlined dense
                                label="Cantidad" v-model="detalle.cantidad"
                                @keyup="calculoDetalles('cantidad')"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                            <v-text-field :rules="reglasDetalles.monto" @keyup="calculoDetalles('monto')"
                                :prefix="monedaPrincipal.simbolo" hide-details="auto" outlined dense label="Monto UND"
                                v-model="detalle.monto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                            <tr>
                                <td><v-text-field prefix="%" hide-details="auto" outlined dense label="Descuento"
                                        v-model="detalle.descuento"></v-text-field></td>
                                <td><v-text-field :prefix="monedaPrincipal.simbolo" hide-details="auto" outlined dense
                                        label="Descuento" v-model="detalle.descuento"></v-text-field></td>
                            </tr>


                        </v-col>
                        <v-col cols="12" lg="3" md="3" sm="6">
                            <v-text-field :prefix="monedaPrincipal.simbolo" hide-details="auto" outlined dense label="IVA"
                                disabled v-model="detalle.montoIVA"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="3" md="3" sm="6">
                            <v-text-field :rules="reglasDetalles.total" @keyup="calculoDetalles('total')"
                                :prefix="monedaPrincipal.simbolo" hide-details="auto" outlined dense label="Total"
                                v-model="detalle.total"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <p v-if="detalle.lote.numeroLote != undefined">Lote: {{ detalle.lote.numeroLote }} (FV:{{
                                FuncionesGenerales.formatoFecha(detalle.lote.fechaVencimiento)
                            }})
                                <v-btn icon color="secundary" @click="ResetLote()">
                                    <v-icon>{{ icons.mdiCached }}</v-icon>
                                </v-btn>
                            </p>

                            <Lote @GetLote="GetLote" :ProductoSucursal="productoDetalle"
                                v-if="productoDetalle && productoDetalle.id && productoDetalle.producto.indLote == 1 && detalle.lote.numeroLote == undefined">
                            </Lote>
                            <small class="error--text"
                                v-if="productoDetalle && productoDetalle.id && productoDetalle.producto.indLote == 1 && detalle.lote.numeroLote == undefined">*
                                Lote es requerido</small>
                        </v-col>

                        <v-col cols="6" class="text-right">
                            <v-btn color="secondary mr-2" small @click="limpiarDetalle()">Limpiar</v-btn>
                            <v-btn color="primary" small @click="addDetalle()">{{ (detalle.id == -1 ?
                                'Agregar' : 'Modificar') }}</v-btn>
                        </v-col>

                    </v-row>
                    <small v-if="cabecera.detalles.length == 0" class="error--text">* Detalles es obligatorio</small>
                </v-form>

                <v-row>
                    <v-col>
                        <v-simple-table fixed-header height="300px">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Producto
                                        </th>
                                        <th class="text-right">
                                            Cant
                                        </th>
                                        <th class="text-right">
                                            Monto
                                        </th>
                                        <th class="text-right">
                                            Descuento
                                        </th>
                                        <th class="text-right">
                                            Imponible
                                        </th>
                                        <th class="text-right">
                                            IVA
                                        </th>
                                        <th class="text-right">
                                            Total
                                        </th>
                                        <th class="text-right">
                                            -
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in cabecera.detalles" :key="i">
                                        <td>
                                            <p class="my-0 py-0">{{ item.producto.producto.nombre }}</p>
                                            <p class="my-0 py-0" v-if="item.lote">
                                                <small v-if="item.lote.numeroLote != undefined">{{ item.lote.numeroLote }}
                                                    {{
                                                        FuncionesGenerales.formatoFecha(item.lote.fechaVencimiento)
                                                    }}</small>
                                            </p>
                                        </td>
                                        <td class="text-right"> {{
                                            FuncionesGenerales.roundToTwo(item.cantidad, 2).toFixed(2)
                                        }}</td>
                                        <td class="text-right"> <span v-if="verMonedaPais"> {{
                                            monedaPais.simbolo
                                        }}</span>
                                            <span v-else> {{ monedaPrincipal.simbolo }}</span> {{
                                                FuncionesGenerales.roundToTwo(item.monto * (verMonedaPais ?
                                                    cabecera.tasaCambio : 1), 2).toFixed(2)
                                            }}
                                        </td>

                                        <td class="text-right">
                                            <p class="my-0 py-0">{{ item.descuentoPorcentaje }}%</p>
                                            <p class="my-0 py-0"><small> <span v-if="verMonedaPais"> {{
                                                monedaPais.simbolo
                                            }}</span>
                                                    <span v-else> {{ monedaPrincipal.simbolo }}</span> {{
                                                        item.descuento
                                                    }}</small> </p>
                                        </td>
                                        <td class="text-right"> <span v-if="verMonedaPais"> {{
                                            monedaPais.simbolo
                                        }}</span>
                                            <span v-else> {{ monedaPrincipal.simbolo }}</span> {{
                                                FuncionesGenerales.roundToTwo(item.baseImponible * (verMonedaPais ?
                                                    cabecera.tasaCambio : 1), 2).toFixed(2)
                                            }}
                                        </td>

                                        <td class="text-right">
                                            <span v-if="verMonedaPais"> {{ monedaPais.simbolo }}</span>
                                            <span v-else> {{ monedaPrincipal.simbolo }}</span> {{
                                                FuncionesGenerales.roundToTwo(item.montoIVA * (verMonedaPais ?
                                                    cabecera.tasaCambio : 1), 2).toFixed(2)
                                            }}
                                            <p class="my-0 py-0"><small>{{ item.tasaImpuesto.abreviatura }}
                                                    {{ item.tasaImpuesto.valor }}%</small></p>
                                        </td>
                                        <td class="text-right"> <span v-if="verMonedaPais"> {{
                                            monedaPais.simbolo
                                        }}</span>
                                            <span v-else> {{ monedaPrincipal.simbolo }}</span> {{
                                                FuncionesGenerales.roundToTwo(item.total * (verMonedaPais ?
                                                    cabecera.tasaCambio : 1), 2).toFixed(2)
                                            }}
                                        </td>
                                        <td>
                                            <v-icon small class="mr-2" @click="(editarDetalle(item))">
                                                {{ icons.mdiPencil }}
                                            </v-icon>
                                            <v-icon small >
                                                {{ icons.mdiDelete }}
                                            </v-icon>

                                        </td>
                                    </tr>

                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>

                <v-row>
                    <v-col cols="12" sm="6" md="8" lg="8">
                        <v-btn color="info" x-small @click="VerMonedaPais()">

                            <span v-if="!verMonedaPais"> {{ `Visualizar en ${monedaPais.simbolo}` }}</span>
                            <span v-else> {{ `Visualizar en ${monedaPrincipal.simbolo}` }} </span>
                        </v-btn>
                        <v-btn class="ml-2" color="secondary" x-small @click="limpiar()">
                            Limpiar
                        </v-btn>
                        <v-btn class="ml-2" color="primary" x-small @click="Guardar()">
                            Guardar
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4">

                        <h5 class="ml-4">Totales </h5>

                        <v-simple-table dense>
                            <template>
                                <tbody>
                                    <tr>
                                        <td>Sub Total</td>
                                        <td class="text-right"> <span v-if="verMonedaPais"> {{
                                            monedaPais.simbolo
                                        }}</span>
                                            <span v-else> {{ monedaPrincipal.simbolo }}</span> {{
                                                FuncionesGenerales.roundToTwo(cabecera.subTotal * (verMonedaPais ?
                                                    cabecera.tasaCambio : 1), 2).toFixed(2)
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Descuento</td>
                                        <td class="text-right"> <span v-if="verMonedaPais"> {{
                                            monedaPais.simbolo
                                        }}</span>
                                            <span v-else> {{ monedaPrincipal.simbolo }}</span> {{
                                                FuncionesGenerales.roundToTwo(cabecera.descuento * (verMonedaPais ?
                                                    cabecera.tasaCambio : 1), 2).toFixed(2)
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Impuesto</td>
                                        <td class="text-right"> <span v-if="verMonedaPais"> {{
                                            monedaPais.simbolo
                                        }}</span>
                                            <span v-else> {{ monedaPrincipal.simbolo }}</span> {{
                                                FuncionesGenerales.roundToTwo(cabecera.impuesto * (verMonedaPais ?
                                                    cabecera.tasaCambio : 1), 2).toFixed(2)
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h4><span class="primary--text">Total Factura</span></h4>
                                        </td>
                                        <td class="text-right">
                                            <h4>
                                                <span class="primary--text">
                                                    <span v-if="verMonedaPais"> {{ monedaPais.simbolo }}</span>
                                                    <span v-else> {{ monedaPrincipal.simbolo }}</span>
                                                    {{
                                                        FuncionesGenerales.roundToTwo(cabecera.total * (verMonedaPais ?
                                                            cabecera.tasaCambio : 1), 2).toFixed(2)
                                                    }}
                                                </span>
                                            </h4>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>

                        </v-simple-table>
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>
        <ModalFinalizar :estatus="estatus" @aceptar="GuardarDb" ref="ModalFinalizarRef"></ModalFinalizar>
    </div>
</template> 
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue';
import SelectProductoSucursal from '@/views/sistema/producto/catalogo/producto/componentes/SelectProductoSucursal.vue';
import { onBeforeMount, ref, watch } from '@vue/composition-api';
import { mdiPencil, mdiDelete, mdiWhatsapp, mdiCached } from '@mdi/js'
import store from '@/store'
import TasaImpuestoSelect from '@/views/sistema/producto/maestro/tasa-impuesto/componentes/SelectJson.vue'
import ProveedorSelect from '@/views/sistema/producto/catalogo/proveedor/componentes/Select.vue'
import MonedaSelectObjeto from '@/views/sistema/configuracion/moneda/componentes/SelectObjeto.vue'
import AreaSelect from '../../../archivo/area/componentes/AreaSelect.vue';
import TipoCambioSelect from '@/views/sistema/configuracion/tipo-cambio/componentes/Select.vue'
import CondicionPagoSelect from '@/views/sistema/configuracion/condicion-pago/componentes/Select.vue'
import FuncionesGenerales from '@/funciones/funciones'
import Lote from '../../../lote/Lote.vue';
import DatePicker from '@/components/DatePicker.vue';
import CompraServices from '@/api/servicios/CompraServices';
import ModalFinalizar from './ModalFinalizar.vue'
import goTo from 'vuetify/lib/services/goto'
export default {
    components: {
        CabeceraEmpresa,
        SelectProductoSucursal,
        TasaImpuestoSelect,
        ProveedorSelect,
        MonedaSelectObjeto,
        AreaSelect,
        TipoCambioSelect,
        CondicionPagoSelect,
        Lote,
        DatePicker,
        ModalFinalizar
    },
    watch: {
        '$store.state.sucursalSelect.id': function () {
            this.limpiar()
        }
    },
    setup(props, context) {
        const monedaPais = ref({})
        const monedaPrincipal = ref({})
        const modal = ref(false)
        const formDetalle = ref(null)
        const form = ref(null)
        const verMonedaPais = ref(false)
        const cabeceraInit = {
            id: -1,
            idArea: 0,
            idEstatus: 6,
            idProveedor: 0,
            idUsuarioValida: null,
            idUsuarioAprobado: null,
            idCondicionPago: null,
            cantidadPlazo: 0,
            idMoneda: null,
            Moneda: {},
            idTipoCambio: null,
            tasaCambio: "",
            numeroFactura: "",
            numeroControl: "",
            subTotal: 0,
            porcentajeDescuento: 0,
            descuento: 0,
            impuesto: 0,
            total: 0,
            fechaHoraInicioValidacion: null,
            FechaHoraFinValidacion: null,
            FechaFactura: null,
            FechaPago: null,
            FechaVencimiento: null,
            detalles: []
        }
        const detalleInit = {
            id: -1,
            producto: {
                nombre: "",
            },
            idProductoSucursal: "",
            tasaImpuesto: {
                descripcion: "",
            },
            idTasaImpuesto: "",
            cantidad: "",
            monto: "",
            descuentoPorcentaje: 0,
            descuento: 0,
            baseImponible: "",
            montoIVA: "",
            total: "",
            lote: {}
        }
        const tasaImpuesto = ref({})
        const detalle = ref({ ...detalleInit })
        const productoDetalle = ref({ id: -1, producto: { indLote: 0 } })
        const cabecera = ref({ ...cabeceraInit })
        const identity = ref(-999999)
        const estatus = [
            {
                id: 3,
                descripcion: "Borrador"
            },
            {
                id: 5,
                descripcion: "En proceso"
            },
            {
                id: 6,
                descripcion: "Finalizado"
            }
        ]

        const ModalFinalizarRef = ref()
        onBeforeMount(() => {
             
        })
        const reglas = {

            idArea: [
                v => !!v || "idArea es requerido"
            ],
            idEstatus: [
                v => !!v || "idEstatus es requerido"
            ],
            idProveedor: [
                v => !!v || "idProveedor es requerido"
            ],
            idCondicionPago: [
                v => !!v || "idCondicionPago es requerido"
            ],
            Moneda: [
                v => !!v || "Moneda es requerido"
            ],
            idMoneda: [
                v => !!v || "idMoneda es requerido"
            ],
            idTipoCambio: [
                v => !!v || "idTipoCambio es requerido"
            ],
            tasaCambio: [
                v => !!v || "tasaCambio es requerido"
            ],
            numeroFactura: [
                v => !!v || "numeroFactura es requerido"
            ],
            numeroControl: [
                v => !!v || "numeroControl es requerido"
            ],
            FechaFactura: [
                v => !!v || "FechaFactura es requerido"
            ],
        }
        const reglasDetalles = {
            producto: [
                v => !!v || "producto es requerido"
            ],
            tasaImpuesto: [

                v => !!v || "tasaImpuesto es requerido"
            ],
            cantidad: [

                v => !!v || "cantidad es requerido",
                v => (!(/[^0-9\.]|(?<=.)-|\.(?=.*\.)/.test(v))) || "No es un valor valido",
            ],
            monto: [

                v => !!v || "monto es requerido",
                v => (!(/[^0-9\.]|(?<=.)-|\.(?=.*\.)/.test(v))) || "No es un valor valido",
            ],

            total: [

                v => !!v || "total es requerido",
                v => (!(/[^0-9\.]|(?<=.)-|\.(?=.*\.)/.test(v))) || "No es un valor valido",
            ]
        }

        const validarForm = () => {
            let val = form.value?.validate()
            if (val == true) {
                if (cabecera.value.detalles.length < 1) {
                    val = false
                }
            }

            return val
        }
        const validarFormDetalle = () => {
            let val = formDetalle.value?.validate()
            if (val == true) {
                if (productoDetalle.value.producto.indLote == true && detalle.value.lote.numeroLote == undefined) {
                    val = false
                }
            }

            return val
        }
        const limpiarDetalle = () => {
            detalle.value = { ...detalleInit }
            productoDetalle.value = { id: -1, producto: { indLote: 0 } }
            tasaImpuesto.value = {}
            formDetalle.value.resetValidation();
        }
        watch(tasaImpuesto, () => {
            calculoDetalles('tasa')
        })
        const calculoDetalles = (tipo) => {
            switch (tipo) {
                case "cantidad":

                    detalle.value.baseImponible = FuncionesGenerales.roundToTwo(detalle.value.cantidad * detalle.value.monto, 2)
                    if (tasaImpuesto.value != null || tasaImpuesto.value != undefined) {
                        detalle.value.montoIVA = tasaImpuesto.value.valor > 0 ? FuncionesGenerales.roundToTwo((detalle.value.baseImponible) * (tasaImpuesto.value.valor / 100), 2) : 0
                    } else {
                        detalle.value.montoIVA = 0
                    }
                    detalle.value.total = FuncionesGenerales.roundToTwo((detalle.value.baseImponible) + detalle.value.montoIVA, 2)


                    break;
                case "monto":
                    detalle.value.baseImponible = FuncionesGenerales.roundToTwo(detalle.value.cantidad * detalle.value.monto, 2)
                    if (tasaImpuesto.value != null || tasaImpuesto.value != undefined) {
                        detalle.value.montoIVA = tasaImpuesto.value.valor > 0 ? FuncionesGenerales.roundToTwo((detalle.value.baseImponible) * (tasaImpuesto.value.valor / 100), 2) : 0

                    } else {
                        detalle.value.montoIVA = 0
                    }
                    detalle.value.total = FuncionesGenerales.roundToTwo((detalle.value.baseImponible) + detalle.value.montoIVA, 2)
                    break;
                case "total":
                    detalle.value.baseImponible = FuncionesGenerales.roundToTwo((detalle.value.total / (1 + (tasaImpuesto.value.valor / 100))), 2)
                    if (tasaImpuesto.value != null || tasaImpuesto.value != undefined) {
                        detalle.value.montoIVA = tasaImpuesto.value.valor > 0 ? FuncionesGenerales.roundToTwo((detalle.value.total - detalle.value.baseImponible), 2) : 0
                    } else {
                        detalle.value.montoIVA = 0
                    }
                    detalle.value.monto = FuncionesGenerales.roundToTwo((detalle.value.total - detalle.value.montoIVA) / detalle.value.cantidad, 2)
                    break;
                case "tasa":
                    detalle.value.baseImponible = FuncionesGenerales.roundToTwo(detalle.value.cantidad * detalle.value.monto, 2)
                    if (tasaImpuesto.value != null || tasaImpuesto.value != undefined) {
                        detalle.value.montoIVA = tasaImpuesto.value.valor > 0 ? FuncionesGenerales.roundToTwo((detalle.value.baseImponible) * (tasaImpuesto.value.valor / 100), 2) : 0
                    } else {
                        detalle.value.montoIVA = 0
                    }
                    detalle.value.total = FuncionesGenerales.roundToTwo((detalle.value.baseImponible) + detalle.value.montoIVA, 2)

                    break;
                default:
                    //alert("aqui")
                    break;
            }
        }

        const GetLote = (dato) => {
            detalle.value.lote = dato
        }

        const editarDetalle = (item) => {
            console.log("editarDetalle")
            console.log(item)
            detalle.value = { ...item }
            detalle.value.lote = { ...detalle.value.lote }
            productoDetalle.value = { ...detalle.value.producto }
            tasaImpuesto.value = { ...detalle.value.tasaImpuesto }
            console.log("productoDetalle")
            console.log(productoDetalle.value)


        }

        const VerMonedaPais = () => {
            if (verMonedaPais.value == false) {
                if (cabecera.value.tasaCambio > 0) {
                    verMonedaPais.value = true
                } else {
                    store.commit('setAlert', {
                        message: "Debe suministrar una tasa",
                        type: 'info',
                    })
                }
            } else {
                verMonedaPais.value = false
            }

        }

        const ResetLote = () => {
            detalle.value.lote = {}
        }
        
                watch(productoDetalle, () => {
        
                    if (productoDetalle.value?.producto != undefined) {
                        console.log("tasa impuesto")
                        console.log(productoDetalle.value)
                        detalle.value.idProductoSucursal = productoDetalle.value.id
                        tasaImpuesto.value = productoDetalle.value.producto.tasaImpuesto
                        if (detalle.value.id == -1) {
                            detalle.value.lote = {}
                        }
        
                    } else {
                        detalle.value.idProductoSucursal = ""
                        tasaImpuesto.value = {}
                        if (detalle.value.id == -1) {
                            detalle.value.lote = {}
                        }
        
                    }
        
                }) 
        const identityGet = () => {
            identity.value = identity.value + 1
            return identity.value
        }

        const calcularTotales = () => {
            let subTotal = 0
            let porcentajeDescuento = 0
            let descuento = 0
            let impuesto = 0
            let total = 0

            cabecera.value.detalles.forEach((item) => {
                subTotal += item.baseImponible
                impuesto += item.montoIVA
            })

            total = subTotal + impuesto - descuento
            cabecera.value.impuesto = impuesto
            cabecera.value.total = total
            cabecera.value.subTotal = subTotal
        }

        const addDetalle = () => {

            if (validarFormDetalle()) {
                if (detalle.value.id == -1) {
                    detalle.value.id = identityGet()

                    detalle.value.idLote = detalle.value.lote.id
                    cabecera.value.detalles.push({
                        ...detalle.value,
                        producto: productoDetalle.value,
                        tasaImpuesto: tasaImpuesto.value,
                        idTasaImpuesto: tasaImpuesto.value.id,
                        idProductoSucursal: productoDetalle.value.id
                    })
                } else {

                    detalle.value.idLote = detalle.value.lote.id
                    cabecera.value.detalles = cabecera.value.detalles.filter(i => i.id != detalle.value.id)
                    cabecera.value.detalles.push({
                        ...detalle.value,
                        producto: productoDetalle.value,
                        tasaImpuesto: tasaImpuesto.value,
                        idTasaImpuesto: tasaImpuesto.value.id,
                        idProductoSucursal: productoDetalle.value.id
                    })
                }
                limpiarDetalle()
                calcularTotales()
            } else {
                store.commit('setAlert', {
                    message: "Verifica todos los datos",
                    type: 'info',
                })

                goTo(0)
            }

        }

        const limpiar = () => {
            cabecera.value = { ...cabeceraInit }
            cabecera.value.detalles = []
            limpiarDetalle()
            form.value.resetValidation();
            setTimeout(() => {
                goTo(0)
            }, 1000)
        }

        const GetEditar = (item) => {
            console.log("GetEditar")
            console.log(item)
            CompraServices.ConsultarDetalles({ id: item.id })
                .then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        cabecera.value = {
                            ...response.data.datos[0],
                            detalles: [],
                            FechaFactura: FuncionesGenerales.formatoFecha(response.data.datos[0].fechaFactura, 4),
                            Moneda: response.data.datos[0].moneda
                        }
                        response.data.datos[0].compraDetalle.forEach(it => {
                            cabecera.value.detalles.push(
                                {
                                    ...it,
                                    producto: { ...it.productoSucursal, lote: it.lote },
                                }
                            )
                        })
                        goTo(0)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    cargandoDatos.value = false
                })

        }
        const guardando = ref(false)
        const GuardarDb = (estatus) => {
            if (estatus.id != undefined) {
                cabecera.value.idEstatus = estatus.id
                //alert("aqui")
                guardando.value = true
                if (cabecera.value.Moneda != null) {
                    cabecera.value.idMoneda = cabecera.value.Moneda.id
                }
                try {
                    CompraServices.Actualizar(
                        store.state.user.idUsuario,
                        cabecera.value
                    ).then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            GetListar(response.data.datos.datos)
                            limpiar()
                        } else {
                            store.commit('setAlert', {
                                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                                type: 'warning',
                            })
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            store.commit('setAlert', {
                                message: error,
                                type: 'error',
                            })
                            guardando.value = false
                        })

                } catch (error) {
                    store.commit('setAlert', {
                        message: error,
                        type: 'error',
                    })
                    guardando.value = false
                }
            } else {
                store.commit('setAlert', {
                    message: `error al seleccionar el estatus`,
                    type: 'warning',
                })
            }
        }
        const GetListar = (item) => {
            context.emit("GetListar", item)
        }
        const Guardar = () => {

            if (validarForm()) {
                ModalFinalizarRef.value.abrir()
            } else {
                store.commit('setAlert', {
                    message: "Verifica todos los datos",
                    type: 'info',
                })

                goTo(0)
            }
        }

        return {
            store,
            icons: {
                mdiPencil,
                mdiDelete,
                mdiCached
            },
            detalle,
            cabecera,
            addDetalle,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal,
            productoDetalle,
            GetLote,
            FuncionesGenerales,
            ResetLote,
            calculoDetalles,
            tasaImpuesto,
            formDetalle,
            reglasDetalles,
            monedaPais,
            monedaPrincipal,
            verMonedaPais,
            VerMonedaPais,
            editarDetalle,
            limpiarDetalle,
            form,
            reglas,
            Guardar,
            limpiar,
            GuardarDb,
            estatus,
            ModalFinalizarRef,
            GetEditar
        }
    }
}
</script>
