//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class CondicionPagoServices {
      
  
     ConsultarSelect(
        datos = {
            Id: -1,
            IdProveedor:-1,
            IdMoneda:-1,
            Descripcion: "", 
            CantidadPlazo:0,
            IndActivo: null,
        }
    ) {
        return http.get("condicionpago/consultar/select", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

    Actualizar(
        IdUsuario,
        Data , 
    ) {   
        const datos = {
            IdUsuario ,
            Data 
          }
          console.log(datos) 
        return http.post("/condicionpago/actualizar",  datos )
          .catch((error) => {
            if (error.response.status == 401) {
              store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
              router.push("/pages/login");
            }
          });
      }
}


export default new CondicionPagoServices();